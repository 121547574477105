import React from "react";
import styled from "styled-components";
import PositionContainer from "../components/shared/container";
import MainTitle from "../components/shared/heroTitles";
import Title from "../components/shared/titles";
import Paragraph from "../components/shared/paragraphs";
import List from "../components/shared/customLists";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import Layout from "../components/shared/layout";
import SupportIco from "../images/support/customer_service.svg";
import Seo from "../components/shared/seo";
// moras jos svugde dodati u TextBox i /shared/lists
//dovrsi media queries da bude responsive page
// napravi last element more reusable

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;

  .img {
    @media (max-width: 1260px) {
      width: 488px;
      height: 488px;
    }
    @media (max-width: 1100px) {
      width: 400px;
      height: 400px;
    }
    @media (max-width: 960px) {
      width: 588px;
      height: 588px;
    }

    @media (max-width: 600px) {
      width: 488px;
      height: 488px;
    }
    @media (max-width: 500px) {
      width: 388px;
      height: 388px;
    }
    @media (max-width: 400px) {
      width: 320px;
      height: 320px;
    }
  }
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: max-content;
  gap: 80px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  @media (max-width: 960px) {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    gap: 40px;
  }
`;

const ParagraphUnderTitle = styled.p`
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #111111;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

const ImgHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    order: 1;
  }
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 486px;
  @media (max-width: 960px) {
    order: 2;
    justify-content: center;
    margin: auto;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
  @media (min-width: 1200px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const RowComments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 475px) {
    &:nth-child(3) {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

const ContentHolderComments = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fbfbfb;
  border: 1px solid #e1e1e1;
  padding: 32px 0;
`;

const TextBoxComments = styled.div`
  width: 792px;
  margin-bottom: 40px;

  @media (max-width: 960px) {
    width: 90%;
  }
`;

const IcoHolder = styled.div`
  width: 80px;
  margin: auto;
  margin-bottom: 24px;
`;

function Support() {
  const intl = useIntl();

  return (
    <Layout>
      <Seo
        title={intl.formatMessage({
          id: "SupportPageTitle",
        })}
        description={intl.formatMessage({
          id: "SupportPageDescription",
        })}
      />
      <Container>
        <PositionContainer>
          <ContentHolder>
            <Column>
              <MainTitle features={true}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: "SupportTitle",
                    }),
                  }}
                ></div>
              </MainTitle>
              <ParagraphUnderTitle>
                {intl.formatMessage({
                  id: "SupportParagraph",
                })}
              </ParagraphUnderTitle>
            </Column>
            <Row>
              <TextBox>
                <IcoHolder>
                  <SupportIco />
                </IcoHolder>
                <Title margin="0px 0px 32px 0px" width="485px">
                  {intl.formatMessage({
                    id: "SupportSectionOneTitle",
                  })}
                </Title>
                <Paragraph>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "SupportSectionOneParagraph",
                      }),
                    }}
                  ></div>
                </Paragraph>
              </TextBox>

              <TextBox>
                <IcoHolder>
                  <SupportIco />
                </IcoHolder>

                <Title margin="0px 0px 32px 0px" width="485px">
                  {intl.formatMessage({
                    id: "SupportSectionTwoTitle",
                  })}
                </Title>
                <Paragraph>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: "SupportSectionTwoParagraph",
                      }),
                    }}
                  ></div>
                </Paragraph>
              </TextBox>
            </Row>
          </ContentHolder>
        </PositionContainer>
      </Container>
      <ContentHolderComments>
        <RowComments>
          <Title margin="0px 0px 32px 0px">
            {intl.formatMessage({
              id: "SupportCommentsTitle",
            })}
          </Title>
        </RowComments>
        <RowComments>
          <TextBoxComments>
            <Paragraph type="center" typeSpecial="left">
              {intl.formatMessage({
                id: "SupportCommentsParagraph",
              })}
            </Paragraph>
          </TextBoxComments>
        </RowComments>
      </ContentHolderComments>
    </Layout>
  );
}

export default Support;
